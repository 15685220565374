import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Tweet } from "../../../server/schema/representations";
import { BASE_URL } from "../consts";

export default function Edit() {
  const [form, setForm] = useState<any>({
    _id: "",
    username: "",
    handle: "",
    time: "",
    time_passed: "",
    summary: "",
    text: "",
    link: "",
    img: "",
    img_text: "",
    comments: "",
    retweets: "",
    likes: "",
    views: "",
    predictions: [],
    verification: {
      player: "",
      club: "",
    },
    impressions: 0,
  });
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const id = params.id?.toString();
      const response = await fetch(
        `${BASE_URL}/admin/tweet/${params.id?.toString()}`
      );

      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const record = await response.json();
      if (!record) {
        window.alert(`Record with id ${id} not found`);
        navigate("/admin");
        return;
      }

      setForm(record);
    }

    fetchData();

    return;
  }, [params.id, navigate]);

  // These methods will update the state properties.
  function updateForm(value: Tweet) {
    return setForm((prev: any) => {
      return { ...prev, ...value };
    });
  }

  async function onSubmit(e: { preventDefault: () => void }) {
    e.preventDefault();
    const editedTweet = {
      username: form.username,
      handle: form.handle,
      time: form.time,
      text: form.text,
      time_passed: form.time_passed,
      img: form.img,
      img_text: form.img_text,
      comments: form.comments,
      retweets: form.retweets,
      likes: form.likes,
      views: form.views,
      predictions: form.predictions || {},
      verification: form.verification,
    };

    // This will send a post request to update the data in the database.
    await fetch(`${BASE_URL}/admin/tweet/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(editedTweet),
      headers: {
        "Content-Type": "application/json",
      },
    });

    navigate("/admin");
  }

  // This following section will display the form that takes input from the user to update the data.
  return (
    <div>
      <h3>Update Tweet</h3>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username: </label>
          <input
            type="text"
            className="form-control"
            id="position"
            value={form.username}
            onChange={(e) => updateForm({ ...form, username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="handle">Handle: </label>
          <input
            type="text"
            className="form-control"
            id="university"
            value={form.handle}
            onChange={(e) => updateForm({ ...form, handle: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="time">Time: </label>
          <input
            type="text"
            className="form-control"
            id="time"
            value={form.time}
            onChange={(e) => updateForm({ ...form, time: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="text">Text: </label>
          <input
            type="text"
            className="form-control"
            id="text"
            value={form.text}
            onChange={(e) => updateForm({ ...form, text: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="time">Time Passed: </label>
          <input
            type="text"
            className="form-control"
            id="time_passed"
            value={form.time_passed}
            onChange={(e) =>
              updateForm({ ...form, time_passed: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="img">Img: </label>
          <input
            type="text"
            className="form-control"
            id="img"
            value={form.img || ""}
            onChange={(e) => updateForm({ ...form, img: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="img text">Img Text: </label>
          <input
            type="text"
            className="form-control"
            id="img_text"
            value={form.img_text || ""}
            onChange={(e) => updateForm({ ...form, img_text: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="comments">Comments: </label>
          <input
            type="text"
            className="form-control"
            id="comments"
            value={form.comments || ""}
            onChange={(e) => updateForm({ ...form, comments: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="retweets">Retweets: </label>
          <input
            type="text"
            className="form-control"
            id="retweets"
            value={form.retweets}
            onChange={(e) => updateForm({ ...form, retweets: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="likes">Likes: </label>
          <input
            type="text"
            className="form-control"
            id="likes"
            value={form.likes}
            onChange={(e) => updateForm({ ...form, likes: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="views">Views: </label>
          <input
            type="text"
            className="form-control"
            id="views"
            value={form.views}
            onChange={(e) => updateForm({ ...form, views: e.target.value })}
          />
        </div>
        <div className="form-group">
          Predicted player:
          <input
            type="text"
            className="form-control"
            id="views"
            value={
              form.predictions
                ? form.predictions[0]
                  ? form.predictions[0].player
                  : ""
                : ""
            }
            onChange={(e) =>
              updateForm({
                ...form,
                predictions: [
                  {
                    ...(form.predictions
                      ? form.predictions[0]
                      : { club: "", certainty: 0 }),
                    player: e.target.value,
                  },
                ],
              })
            }
          />
        </div>
        <div className="form-group">
          Predicted club:
          <input
            type="text"
            className="form-control"
            id="views"
            value={
              form.predictions
                ? form.predictions[0]
                  ? form.predictions[0].club
                  : ""
                : ""
            }
            onChange={(e) =>
              updateForm({
                ...form,
                predictions: [
                  {
                    ...(form.predictions
                      ? form.predictions[0]
                      : { player: "", certainty: 0 }),
                    club: e.target.value,
                  },
                ],
              })
            }
          />
        </div>
        <div className="form-group">
          Predicted certainty:
          <input
            type="text"
            className="form-control"
            id="views"
            value={
              form.predictions
                ? form.predictions[0]
                  ? form.predictions[0].certainty
                  : 0
                : 0
            }
            onChange={(e) =>
              updateForm({
                ...form,
                predictions: [
                  {
                    ...(form.predictions
                      ? form.predictions[0]
                      : { player: "", club: "" }),
                    certainty: e.target.value as unknown as number,
                  },
                ],
              })
            }
          />
        </div>

        <br />

        <div className="form-group">
          <input
            type="submit"
            value="Update Record"
            className="btn btn-primary"
          />
        </div>
      </form>
    </div>
  );
}
