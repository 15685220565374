import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import ActivityFeed from "../ActivityFeed/ActivityFeed";
import MainNav from "../components/MainNav";
import Dashboard from "../components/Dashboard";
import SignInModal from "../components/SignInModal";

type HomePageProps = {
  initializeGoogleAuth: () => void;
  isLoggingIn: boolean;
  setIsLoggingIn: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = createUseStyles({
  header: {
    position: "fixed",
    top: "0",
  },
  middleBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 12px 12px rgba(0, 0, 0, 0.25)",
    width: "55%",
    height: "100vh", 
    '@media screen and (max-width: 425px)': {
      width: '90%',
    },
  },
  pageLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  content: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
});

const HomePage = (props: HomePageProps) => {
  const classes = useStyles();

  return (
    <div className={classes.pageLayout}>
      <div className={classes.middleBox}>
        <div className={classes.header}>
          <MainNav />
        </div>
        <div className={classes.content}>
          {/* <SignInModal
            isOpen={props.isLoggingIn}
            onClose={() => props.setIsLoggingIn(false)}
            initializeGoogleAuth={props.initializeGoogleAuth}
          /> */}
          <ActivityFeed />
          {/* <Dashboard /> */}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
