import { useEffect, useState } from "react";
import {
  getArticles,
  getTweets,
  getAuthors,
  getRecentTweets,
  getRecentArticles,
} from "../utils/contentFunctions";
import {
  Article,
  Author,
  News,
  Tweet,
} from "../../../server/schema/representations";
import NewsCard from "./NewsCard";
import { createUseStyles } from "react-jss";
import {
  newsRankSort,
  orderActivityFeedNews,
} from "../utils/processingFunctions";
import PinnedCard from "./PinnedCard";
import Search from "../components/SearchBar";
import Team from "../components/Team";
import { Box, Container, Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingUI from "../components/LoadingUI";
import RatingExplanationModal from "../components/RatingExplanationModal";

interface ActivityFeedProps {}

const useStyles = createUseStyles({
  // contains top and feed
  container: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    display: "flex",
    flexDirection: "column",
    maxWidth: "80rem",
    alignItems: "center",
  },
  // aligns w feed
  top: {
    position: "fixed",
    top: "6rem",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    margin: "0.5rem",
    '@media screen and (max-width: 425px)': {
      width: '85%',
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1rem",
    width: "100%",
  },
  search: {
    width: "100%",
  },
  scroll: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  // aligns w top
  feed: {
    height: "40rem",
    overflow: "auto",
    position: "fixed",
    top: "10rem",
    width: "52%",
    '@media screen and (max-width: 425px)': {
      width: '85%',
    },
  },
  cards: {
    margin: "0.5rem",
    width: "97%",
  },
});

const ActivityFeed: React.FC<ActivityFeedProps> = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadedNews, setLoadedNews] = useState<News[]>();
  const [loadedNewsCount, setLoadedNewsCount] = useState(0);
  const [authorPercentiles, setAuthorPercentiles] =
    useState<Map<String, number>>();
  const [selectedTeams, setSelectedTeams] = useState();
  const [displayRatingExplanation, setDisplayRatingExplanation] =
    useState(false);

  const convertTweets = (tweets: Tweet[]) => {
    return tweets.map((tweet) => {
      return {
        id: tweet._id,
        type: "tweet",
        summary: tweet.summary,
        text: tweet.text,
        author: tweet.username,
        percentile: authorPercentiles!.get(
          tweet.username?.toLowerCase().replace(" ", "_")
        ),
        time: tweet.time,
        link: tweet.link,
        img: tweet.img,
        likes: tweet.likes,
        comments: tweet.comments,
        score: 0,
        impressions: tweet.impressions + 1,
      };
    });
  };

  const convertArticles = (articles: Article[]) => {
    return articles.map((article) => ({
      id: article.id,
      type: "article",
      summary: article.summary,
      text: article.text,
      author: article.authors[0],
      publisher: article.publisher,
      percentile: authorPercentiles!.get(
        article.authors[0]?.toLowerCase().replace(" ", "_")
      ),
      time: article.time,
      link: article.link,
      img: article.image,
      likes: "",
      comments: "",
      score: 0,
      impressions: article.impressions + 1,
    }));
  };

  /** Returns a filtered array of News objects based on the given search Query
   */
  const onSearch = (searchQuery: string): void => {
    console.log("searchQuery: ", searchQuery);
    const filteredNews = [];
    const searchQueryLowerCase = searchQuery.toLowerCase();
    const searchQueryWords = searchQueryLowerCase.split(" ");

    for (const news of loadedNews ?? []) {
      const newsSummaryLowerCase = news.summary.toLowerCase();
      const newsTextLowerCase = news.text.toLowerCase();

      let matchCount = 0;

      for (const word of searchQueryWords) {
        if (
          newsSummaryLowerCase.includes(word) ||
          newsTextLowerCase.includes(word)
        ) {
          matchCount++;
        }
      }

      // Create a new object with matchCount property
      const filteredNewsItem = { ...news, matchCount };
      filteredNews.push(filteredNewsItem);
    }

    // Sort filteredNews based on matchCount in descending order
    filteredNews.sort((a, b) => b.matchCount - a.matchCount);

    // Remove matchCount property from each object
    const filteredNewsWithoutMatchCount: News[] = filteredNews.map(
      ({ matchCount, ...rest }) => rest
    );

    setLoadedNews(filteredNewsWithoutMatchCount);
  };

  const onTeamSelect = (teamValue: number): void => {
    console.log("team value selected", teamValue);
    const aliases: Record<number, string[]> = {
      0: ["All Teams"],
      1: ["Arsenal"],
      2: ["Aston Villa"],
      3: ["Bournemouth"],
      4: ["Brentford"],
      5: ["Brighton & Hove Albion"],
      6: ["Burnley"],
      7: ["Chelsea"],
      8: ["Crystal Palace"],
      9: ["Everton"],
      10: ["Fulham"],
      11: ["Liverpool"],
      12: ["Luton Town"],
      13: ["Liverpool"],
      14: ["Manchester City"],
      15: ["Manchester United"],
      16: ["Newcastle"],
      17: ["Nottingham Forest"],
      18: ["Tottenham Hotspur"],
      19: ["West Ham United"],
      20: ["Wolverhampton Wanderers"],
    };

    const teamAliases =
      aliases[teamValue]?.map((alias) => alias.toLowerCase()) || [];

    const filteredNews = loadedNews?.map((news) => {
      const newsSummaryLowerCase = news.summary.toLowerCase();
      const newsTextLowerCase = news.text.toLowerCase();

      let matchCount = 0;

      for (const alias of teamAliases) {
        if (
          newsSummaryLowerCase.includes(alias) ||
          newsTextLowerCase.includes(alias)
        ) {
          console.log("hit!");
          matchCount++;
        }
      }

      return { ...news, matchCount };
    });

    if (filteredNews) {
      filteredNews.sort((a, b) => b.matchCount - a.matchCount);
      const filteredNewsWithoutMatchCount = filteredNews.map(
        ({ matchCount, ...rest }) => rest
      );
      setLoadedNews(filteredNewsWithoutMatchCount);
    }
  };

  useEffect(() => {
    const initializeContent = async () => {
      // Load selectedTeams from local storage on component mount
      const teamsOfInterest = localStorage.getItem("selectedTeams");

      console.time("fetching tweets, articles, and authors from db");
      const storedTweets = (await getRecentTweets(0, 10)) || [];
      console.log("fetching articles");
      const storedArticles = (await getRecentArticles(0, 10)) || [];
      console.log("fetching authors");
      const storedAuthors = (await getAuthors()) || [];
      console.timeEnd("fetching tweets, articles, and authors from db");

      console.time("set author percentiles");
      const totalAuthors = storedAuthors.length;
      const percentileThresholds: number[] = [];

      for (let i = 1; i <= 100; i++) {
        const threshold = (i / 100) * totalAuthors;
        percentileThresholds.push(threshold);
      }

      // Calculate the percentile for each author
      const authorPercentilesMap = new Map<String, number>();

      storedAuthors.forEach((author, index) => {
        // Find the percentile rank for the author
        let percentile = 0;
        for (let i = 0; i < percentileThresholds.length; i++) {
          if (index < percentileThresholds[i]) {
            percentile = 100 - i;
            break;
          }
        }

        // Store the author and their percentile in the map
        authorPercentilesMap.set(author.name, percentile);
      });
      console.timeEnd("set author percentiles");

      const convertedTweets = storedTweets.map((tweet) => {
        return {
          id: tweet._id,
          type: "tweet",
          summary: tweet.summary,
          text: tweet.text,
          author: tweet.username,
          percentile: authorPercentilesMap.get(
            tweet.username?.toLowerCase().replace(" ", "_")
          ),
          time: tweet.time,
          link: tweet.link,
          img: tweet.img,
          likes: tweet.likes,
          comments: tweet.comments,
          score: 0,
          impressions: tweet.impressions + 1,
        };
      });

      const convertedArticles = storedArticles.map((article) => ({
        id: article.id,
        type: "article",
        summary: article.summary,
        text: article.text,
        author: article.authors[0],
        publisher: article.publisher,
        percentile: authorPercentilesMap.get(
          article.authors[0]?.toLowerCase().replace(" ", "_")
        ),
        time: article.time,
        link: article.link,
        img: article.image,
        likes: "",
        comments: "",
        score: 0,
        impressions: article.impressions + 1,
      }));

      console.time("various ranking algorithms");
      const newsWrapper: News[] = orderActivityFeedNews(
        convertedTweets.concat(convertedArticles)
      );
      const sortedNewsWrapper: News[] = newsRankSort(
        newsWrapper,
        authorPercentilesMap,
        teamsOfInterest
      );
      console.timeEnd("various ranking algorithms");

      setAuthorPercentiles(authorPercentilesMap);
      setLoadedNews(sortedNewsWrapper);
      setLoadedNewsCount(10);
      if (teamsOfInterest) {
        setSelectedTeams(JSON.parse(teamsOfInterest));
      }
    };
    console.time("initialize content function");
    initializeContent();
    console.timeEnd("initialize content function");
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingUI />;
  } else {
    console.time("rendering activity feed");
    return (
      <>
        <div className={classes.container}>
          <div className={classes.top}>
            <div className={classes.gridContainer}>
              <div className={classes.search}>
                <Search onSearch={onSearch} />
              </div>

              <Team onTeamSelect={onTeamSelect} />
            </div>
          </div>
          {loadedNews?.length! ? (
            <div id="scrollableDiv" className={classes.feed}>
              <InfiniteScroll
                dataLength={loadedNewsCount}
                next={async () => {
                  const newTweets =
                    (await getRecentTweets(
                      loadedNewsCount,
                      loadedNewsCount + 10
                    )) || [];
                  const newArticles =
                    (await getRecentArticles(
                      loadedNewsCount,
                      loadedNewsCount + 10
                    )) || [];

                  const convertedTweets = convertTweets(newTweets);
                  const convertedArticles = convertArticles(newArticles);
                  const newsWrapper: News[] =
                    convertedTweets.concat(convertedArticles);

                  // Replaced by [newsRankSort]
                  // const orderedNews = orderActivityFeedNews(newsWrapper);
                  setLoadedNews(() => loadedNews!.concat(newsWrapper));
                  setLoadedNewsCount(() => loadedNewsCount + 10);
                }}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
                className={classes.scroll}
              >
                <div className={classes.cards}>
                  {loadedNews?.map((newsObj: News, index: number) =>
                    index === 0 ? (
                      <PinnedCard
                        news={newsObj}
                        setDisplayRatingExplanation={
                          setDisplayRatingExplanation
                        }
                      />
                    ) : (
                      <NewsCard
                        news={newsObj}
                        setDisplayRatingExplanation={
                          setDisplayRatingExplanation
                        }
                      />
                    )
                  )}
                </div>
              </InfiniteScroll>
            </div>
          ) : null}
          {displayRatingExplanation ? (
            <RatingExplanationModal
              setDisplayRatingExplanation={setDisplayRatingExplanation}
            />
          ) : null}
        </div>
        {console.timeEnd("rendering activity feed")}
      </>
    );
  }
};

export default ActivityFeed;
