import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import MainNav from "../components/MainNav";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Search from "../components/SearchBar";
import TeamsCollection from "../components/TeamsCollection";
import { teamCodes } from "../components/TeamsCollection";

const useStyles = createUseStyles({
  header: {
    position: "fixed",
    top: "0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: "1000",
    backgroundColor: "white",
  },
  top: {
    position: "fixed",
    top: "5rem",
    display: "flex",
    flexDirection: "column",
    zIndex: "500",
    backgroundColor: "white",
    width: "50%",
    alignItems: "center",
    paddingBottom: "1rem",
  },
  middleBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 12px 12px rgba(0, 0, 0, 0.25)",
    width: "55%", 
    '@media screen and (max-width: 425px)': {
      width: '90%',
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    '@media screen and (max-width: 425px)': {
      width: '80%',
    },
  },
  meat: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: "10rem",
    zIndex: "100",
    '@media screen and (max-width: 425px)': {
      width: '80%',
    },
  },
  prompt: {
    '@media screen and (max-width: 320px)': {
      fontSize: '12px',
    },
  },
  search: {
    width: "100%",
  },
  bottom: {
    display: "flex",
    position: "fixed",
    bottom: "0rem",
    backgroundColor: "rbga(34,34,34, .75)",
    zIndex: "1000",
    width: "100%",
    justifyContent: "center",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    backdropFilter: "blur(10px)",
  },
  buttonStyle: {
    width: "24rem",
  },
});

const PreferencesPage = () => {
  const getInitialSelectedTeams = () => {
    const storedTeams = localStorage.getItem("selectedTeams");

    // Check if storedTeams is not null, parse the JSON, or provide a default value
    return storedTeams ? JSON.parse(storedTeams) : [];
  };

  const classes = useStyles();
  const [selectedTeams, setSelectedTeams] = useState<string[]>(
    getInitialSelectedTeams()
  );
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleTeamCardSelect = (teamName: string) => {
    setSelectedTeams((prevSelectedTeams) => {
      if (prevSelectedTeams.length === teamCodes.length) {
        // If all teams are selected (teamCodes.length - 1 of these), deselect all and return an empty array
        return [];
      } else if (prevSelectedTeams.includes(teamName)) {
        // Remove the teamName if it's already selected
        return prevSelectedTeams.filter((name) => name !== teamName);
      } else {
        if (teamName === "all") {
          return teamCodes;
        } else {
          // Add the teamName if it's not selected
          return [...prevSelectedTeams, teamName];
        }
      }
    });
  };

  const persistTeamSelection = () => {
    // remove 'all' from selectedTeams, if applicable
    const filteredSelectedTeams = selectedTeams.filter(
      (team) => team !== "all"
    );
    localStorage.setItem(
      "selectedTeams",
      JSON.stringify(filteredSelectedTeams)
    );
  };

  const onSearch = (query: string) => {
    setSearchTerm(query);
  };

  return (
    <div className={classes.middleBox}>
      <div className={classes.content}>
        <div className={classes.header}>
          <MainNav />
        </div>
        <div className={classes.top}>
          <span className={classes.prompt}>Which team(s) do you want transfer news for?</span>
          <div className={classes.search}>
            <Search onSearch={onSearch} />
          </div>
        </div>

        <div className={classes.meat}>
          <TeamsCollection
            selectedTeams={selectedTeams}
            handleTeamCardSelect={handleTeamCardSelect}
            searchTerm={searchTerm}
          />
        </div>
        <div className={classes.bottom}>
          {selectedTeams.length >= 1 ? (
            <Link to={`/home`}>
              <Button
                className={classes.buttonStyle}
                variant="contained"
                onClick={persistTeamSelection}
                color="error"
              >
                Save
              </Button>
            </Link>
          ) : (
            <Button className={classes.buttonStyle} variant="contained" disabled>
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreferencesPage;
