import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { News } from "../../../server/schema/representations";
import { Grid } from "@mui/material";
import { timePassed } from "../utils/processingFunctions";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "12rem",
    minWidth: "30rem",
    width: "100%",
    color: "gray",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgb(0 0 0 / 0.2)",
    margin: "1rem 0",
    padding: "1.5rem 1.5rem 1.5rem 1.5rem",
    "&:hover": {
      backgroundColor: "#db3939",
      color: "#FFFFFF",
      // transform: "scale(1.05)",
      cursor: "pointer",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "0.8",
    justifyContent: "space-between",
    marginRight: "0.5rem",
  },
  blurb: {
    display: "flex",
    alignItems: "center",
    marginTop: ".5rem",
    marginBottom: "0.5rem",
  },
  title: {
    color: "#646464",
    fontWeight: "bold",
  },
  hoveredTitle: {
    color: "white",
    fontWeight: "bold",
  },
  metrics: {
    fontFamily: "TT Norms Pro",
    marginLeft: "0.25rem",
    marginRight: "1rem",
  },
  picture: {
    display: "flex",
    flexGrow: "0.2",
    height: "9rem",
  },
  ellipse: {
    width: "1rem",
  },
  icon: {
    width: "1rem",
  },
  a: {
    textDecoration: "none" /* Remove underline */,
    color: "inherit",
  },
});

interface NewsCardProps {
  news: News;
  setDisplayRatingExplanation: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewsCard: React.FC<NewsCardProps> = ({news, setDisplayRatingExplanation}) => {
  const [hovered, setHovered] = useState(false);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const storedIsLiked = localStorage.getItem(news.id);
    if (storedIsLiked) {
      setLiked(true);
    }
  }, [news.id]);

  const toggleLike = () => {
    const storedIsLiked = localStorage.getItem(news.id);
    if (storedIsLiked) {
      localStorage.removeItem(news.id);
      setLiked(false);
    } else {
      localStorage.setItem(news.id, "true");
      setLiked(true);
    }
  }


  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };

  const classes = useStyles();

  const {
    id,
    type,
    summary,
    text,
    author,
    publisher,
    percentile,
    time,
    link,
    img,
    likes,
    comments,
    score,
    impressions,
  } = news;

  const timeAgo = timePassed(new Date(time));

  const renderMetrics = (): React.ReactNode => {
    return (
      <div>
        {likes !== "" && comments !== "" ? (
          <>
            <img className={classes.icon} src="./images/impressions.svg" alt="" />
            <span className={classes.metrics}>{impressions}</span>
            <img className={classes.icon} onClick={toggleLike} src={liked ? "./images/red_heart.svg" : "./images/heart.svg" } alt="" />
            <span className={classes.metrics}>{likes}</span>
            <img className={classes.icon} src="./images/comment.svg" alt="" />
            <span className={classes.metrics}>{comments}</span>
          </>
        ) : null}
        {percentile ? (
          <>
            <img
              className={classes.icon}
              src="./images/reliabilityRating.svg"
              alt=""
              onMouseEnter={() => setDisplayRatingExplanation(true)}
            />
            <span className={classes.metrics}>{percentile}</span>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <a
      href={
        link !== ""
          ? link.indexOf("http") !== -1
            ? link
            : "https://www." + link
          : undefined
      }
      className={classes.a}
      target="_blank"
      rel="noreferrer"
    >
      <div
        className={classes.container}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        <Grid container spacing={2}>
          <Grid item xs={8} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div className={classes.body}>
              <div>
                <div className={!hovered ? classes.title : classes.hoveredTitle}>
                  {summary.split(".")[0].length > 120
                    ? summary.split(".")[0].slice(0, 120) + "..."
                    : summary.split(".")[0]}
                </div>
                <div className={classes.blurb}>
                  <img src="./images/ellipse.svg" alt="" />
                  <div style={{ marginLeft: "0.25rem" }}>
                    {type === "tweet"
                      ? author + " via Twitter"
                      : author === undefined
                      ? publisher
                      : author + " via " + publisher}{" "}
                    | {timeAgo}
                  </div>
                </div>
              </div>
              {renderMetrics()}
            </div>
          </Grid>

          <Grid item xs={4}>
            <img
              className={classes.picture}
              src={img || "./images/defaultNewsImg.png"}
              alt=""
            />
          </Grid>
      </Grid>
      </div>
    </a>
  );
};

export default NewsCard;
