import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Record as RecordType,
  Tweet as TweetType,
} from "../../../server/schema/representations";
import { BASE_URL } from "../consts";

interface RecordProps {
  record: RecordType;
  deleteRecord: (id: string) => void;
}

interface TweetProps {
  tweet: TweetType;
  deleteRecord: (id: string) => void;
}

const editButton = (type: string, id: string) => (
  <Link className="btn btn-link" to={`/admin/${type}/edit/${id}`}>
    Edit
  </Link>
);

const deleteButton = (action: () => void) => (
  <button className="btn btn-link" onClick={action}>
    Delete
  </button>
);

const Record: React.FC<RecordProps> = (props) => {
  const recordFields = [
    {
      view: <td>{props.record.name}</td>,
      title: "Name",
    },
    {
      view: <td>{props.record.position}</td>,
      title: "Position",
    },
    {
      view: <td>{props.record.university}</td>,
      title: "University",
    },
  ];
  return (
    <tr>
      {recordFields.map((recordObjRenderer) => recordObjRenderer.view)}
      <td>
        {editButton("record", props.record._id)} |
        {deleteButton(() => props.deleteRecord(props.record._id))}
      </td>
    </tr>
  );
};

const Tweet: React.FC<TweetProps> = (props) => {
  const tweetFields = [
    {
      view: <td>{props.tweet._id}</td>,
      title: "ID",
    },
    {
      view: <td>{props.tweet.username}</td>,
      title: "Username",
    },
    {
      view: <td>{props.tweet.handle}</td>,
      title: "Handle",
    },
    {
      view: <td>{props.tweet.time}</td>,
      title: "Time",
    },
    {
      view: <td>{props.tweet.time_passed}</td>,
      title: "Time Passed",
    },
    {
      view: <td>{props.tweet.text}</td>,
      title: "Text",
    },
    {
      view: <td>{props.tweet.img}</td>,
      title: "Img",
    },
    {
      view: <td>{props.tweet.img_text}</td>,
      title: "Img Text",
    },
    {
      view: <td>{props.tweet.comments}</td>,
      title: "Retweets",
    },
    {
      view: <td>{props.tweet.retweets}</td>,
      title: "Retweets",
    },
    {
      view: <td>{props.tweet.likes}</td>,
      title: "Likes",
    },
    {
      view: <td>{props.tweet.views}</td>,
      title: "Views",
    },
    {
      view: (
        <td>
          {props.tweet.predictions
            ?.map(
              (prediction) =>
                prediction.player +
                " " +
                prediction.club +
                " " +
                prediction.certainty
            )
            .join(",")}
        </td>
      ),
      title: "Predictions",
    },
    {
      view: (
        <td>
          {props.tweet.verification?.club +
            " " +
            props.tweet.verification?.player}
        </td>
      ),
      title: "Verification",
    },
  ];
  return (
    <tr>
      {tweetFields.map((tweetObjRenderer) => tweetObjRenderer.view)}
      <td>
        {editButton("tweet", props.tweet._id)} |
        {deleteButton(() => props.deleteRecord(props.tweet._id))}
      </td>
    </tr>
  );
};

export default function DatabaseRenderer() {
  const [records, setRecords] = useState<RecordType[]>([]);
  const [tweets, setTweets] = useState<TweetType[]>([]);

  // Fetches data to be rendered from the database.
  useEffect(() => {
    async function getRecords() {
      try {
        const response = await fetch(`${BASE_URL}/admin/record/`);

        if (!response.ok) {
          throw new Error(`An error occurred: ${response.statusText}`);
        }

        const records: RecordType[] = await response.json();
        setRecords(records);
      } catch (error) {
        window.alert((error as Error).message);
      }
    }

    async function getTweets() {
      try {
        const response = await fetch(`${BASE_URL}/admin/tweet/`);

        if (!response.ok) {
          throw new Error(`An error occurred: ${response.statusText}`);
        }

        const tweets: TweetType[] = await response.json();
        setTweets(tweets);
      } catch (error) {
        window.alert((error as Error).message);
      }
    }

    getRecords();
    getTweets();
  }, []);

  // Deletes a record
  async function deleteRecord(id: string) {
    await fetch(`${BASE_URL}/admin/record/${id}`, {
      method: "DELETE",
    });

    const newRecords = records.filter((el) => el._id !== id);
    setRecords(newRecords);
  }

  // Deletes a tweet
  async function deleteTweet(id: string) {
    await fetch(`${BASE_URL}/admin/tweet/${id}`, {
      method: "DELETE",
    });

    const newTweets = tweets.filter((el) => el._id !== id);
    setTweets(newTweets);
  }

  // Maps out the records on the table
  function recordList() {
    return records.map((record) => {
      return (
        <Record
          record={record}
          deleteRecord={() => deleteRecord(record._id)}
          key={record._id}
        />
      );
    });
  }

  // Maps out the tweets on the table
  function tweetList() {
    return tweets.map((tweet) => {
      return (
        <Tweet
          tweet={tweet}
          deleteRecord={() => deleteTweet(tweet._id)}
          key={tweet._id}
        />
      );
    });
  }

  // Display the table with the records of individuals.
  return (
    <div>
      <h3>Record List</h3>
      <table className="table table-striped" style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>University</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{recordList()}</tbody>
      </table>

      <h3>Tweets List</h3>
      <table className="table table-striped" style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>_ID</th>
            <th>Username</th>
            <th>Handle</th>
            <th>Time</th>
            <th>Time Passed</th>
            <th>Text</th>
            <th>Img</th>
            <th>Img Text</th>
            <th>Comments</th>
            <th>Retweets</th>
            <th>Likes</th>
            <th>Views</th>
            <th>Predictions</th>
            <th>Verification</th>
          </tr>
        </thead>
        <tbody>{tweetList()}</tbody>
      </table>
    </div>
  );
}
