import React from 'react';
import { createUseStyles } from "react-jss";
import MainNav from '../components/MainNav';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';


const useStyles = createUseStyles({
  outerContainer: {
    display: 'flex',
    width: '100%',
    background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0) 20%, #ffffff 20%)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '6rem',
  },
  subtitle: {
    height: '100%',
  },
  meat: {
    display: 'flex',
    height: '100%',
    width: '24rem',
    textAlign: 'center',
    marginTop: '2rem',
    '@media screen and (max-width: 375px)': {
      width: '20rem',
    },
    '@media screen and (max-width: 320px)': {
      width: '16rem',
    },
  },
  getStarted: {
    position: 'fixed',
    bottom: '1rem', 
  },
  buttonStyle: {
    width: '24rem',
    '@media screen and (max-width: 375px)': {
      width: '20rem',
    },
    '@media screen and (max-width: 320px)': {
      width: '16rem',
    },
  }
})

const LandingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <div className={classes.content}>
        <MainNav />
        
          <div className={classes.subtitle}>
            Transfer News, powered by AI.
          </div>

          <div className={classes.meat}>
            Delivering the English Premier League transfer updates that matter most to you – curated with precision, tailored to your personal interests, and sourced with unwavering reliability and accuracy.
          </div>
          
          <Link className={classes.getStarted} to={`/preferences`}>
            <Button
              variant="contained"
              className={classes.buttonStyle}
              color='error'
              size='large'
            >
              Get Started
            </Button>
          </Link>

      </div>
    </div>
  );
};

export default LandingPage