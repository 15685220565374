import { Tweet, Article, Author } from "../../../server/schema/representations";
import { BASE_URL } from "../consts";

export const localTweetAPI = `${BASE_URL}/admin/tweet`;
export const localArticleAPI = `${BASE_URL}/admin/article`;
export const localAuthorAPI = `${BASE_URL}/admin/author`;

// gets most recent tweets limited
export const getTweets = async () => {
  console.log("requesting from ", localTweetAPI);
  try {
    const response = await fetch(localTweetAPI);

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    const tweets: Tweet[] = await response.json();
    return tweets;
  } catch (error) {
    console.log((error as Error).message);
    console.log("error getting tweets");
    return;
  }
};

export const getRecentTweets = async (start: number, end: number) => {
  try {
    const response = await fetch(`${localTweetAPI}/start/${start}/end/${end}`);

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    const tweets: Tweet[] = await response.json();
    tweets.forEach((tweet) => { 
      editTweet(tweet._id, {...tweet, impressions: tweet.impressions + 1})
    });
    return tweets;
  } catch (error) {
    console.log((error as Error).message);
    console.log("error getting tweets");
    return;
  }
};

export const editTweet = async (id: string, tweet: Tweet) => {
  try {
    await fetch(`${localTweetAPI}/${id}`, {
      method: "PATCH",
      body: JSON.stringify(tweet),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
    console.log("error editing a tweet");
    return;
  }
};

export const getArticles = async () => {
  console.log("requesting from ", localArticleAPI);
  try {
    const response = await fetch(localArticleAPI);

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    const articles: Article[] = await response.json();
    articles.forEach((article) => { 
      editArticle(article.id, {...article, impressions: article.impressions + 1})
    });
    return articles;
  } catch (error) {
    console.log((error as Error).message);
    console.log("error getting articles");
    return;
  }
};

export const getRecentArticles = async (start: number, end: number) => {
  try {
    const response = await fetch(
      `${localArticleAPI}/start/${start}/end/${end}`
    );

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    const articles: Article[] = await response.json();
    return articles;
  } catch (error) {
    console.log((error as Error).message);
    console.log("error getting articles");
    return;
  }
};

export const editArticle = async (id: string, article: Article) => {
  try {
    await fetch(`${localArticleAPI}/${id}`, {
      method: "PATCH",
      body: JSON.stringify(article),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
    console.log("error editing an article");
    return;
  }
};

export const getAuthors = async () => {
  console.log("requesting from ", localAuthorAPI);
  try {
    const response = await fetch(localAuthorAPI);

    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }

    const authors: Author[] = await response.json();
    return authors;
  } catch (error) {
    console.log((error as Error).message);
    console.log("error getting authors");
    return;
  }
};

/** Returns sorted list of authors, ordered in decreasing order of points */
export const getAuthorsRanked = async () => {
  return (await getAuthors())?.sort((a, b) => b.points - a.points);
};

/** Returns the percentile of the specified author based on their position in the
 * author ranking
 */
export const getAuthorPercentile = async (name: string) => {
  const rankedAuthorsList = await getAuthorsRanked();
  const rank = rankedAuthorsList?.findIndex((author) => author.name === name);
  const numAuthors = rankedAuthorsList?.length;

  // default to -1 if we are unable to compute a percentile
  if (!rank || rank === -1 || !numAuthors || numAuthors === 0) return -1;

  console.log("rank", rank);
  console.log("numAuthors", numAuthors);

  return ((numAuthors - rank) / numAuthors) * 100;
};
