import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { BASE_URL } from "../consts";

export default function Edit() {
  type Record = {
    name?: string;
    position?: string;
    university?: string;
  };

  const [form, setForm] = useState<Record>({
    name: "",
    position: "",
    university: "",
  });
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const id = params.id?.toString();
      const response = await fetch(
        `${BASE_URL}/admin/record/${params.id?.toString()}`
      );

      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const record = await response.json();
      if (!record) {
        window.alert(`Record with id ${id} not found`);
        navigate("/admin");
        return;
      }

      setForm(record);
    }

    fetchData();

    return;
  }, [params.id, navigate]);

  // These methods will update the state properties.
  function updateForm(value: Record) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  async function onSubmit(e: { preventDefault: () => void }) {
    e.preventDefault();
    const editedPerson = {
      name: form.name,
      position: form.position,
      university: form.university,
    };

    // This will send a post request to update the data in the database.
    await fetch(`${BASE_URL}/admin/record/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(editedPerson),
      headers: {
        "Content-Type": "application/json",
      },
    });

    navigate("/admin");
  }

  // This following section will display the form that takes input from the user to update the data.
  return (
    <div>
      <h3>Update Record</h3>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name: </label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={form.name}
            onChange={(e) => updateForm({ name: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="position">Position: </label>
          <input
            type="text"
            className="form-control"
            id="position"
            value={form.position}
            onChange={(e) => updateForm({ position: e.target.value })}
          />
        </div>
        <div className="form-group">
          <div className="form-group">
            <label htmlFor="university">University: </label>
            <input
              type="text"
              className="form-control"
              id="university"
              value={form.university}
              onChange={(e) => updateForm({ university: e.target.value })}
            />
          </div>
        </div>
        <br />

        <div className="form-group">
          <input
            type="submit"
            value="Update Record"
            className="btn btn-primary"
          />
        </div>
      </form>
    </div>
  );
}
