import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { News } from "../../../server/schema/representations";
import { timePassed } from "../utils/processingFunctions";
import { getAuthorPercentile } from "../utils/contentFunctions";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // height: "25rem",
    // width: "40rem",
    color: "#FFFFFF",
    // maxWidth: "50rem",
    background: "#db3939",
    borderRadius: "10px",
    boxShadow: "0 0 10px gray",
    margin: "1rem 0",
    padding: "1.5rem 1.5rem 1.5rem 1.5rem",
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
  blurb: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    color: "white",
    fontWeight: "bold",
    alignItems: "center",
    // marginBottom: "0.5rem",
  },
  metrics: {
    fontFamily: "TT Norms Pro",
    marginTop: "0.5rem",
    marginLeft: "0.25rem",
    marginRight: "1rem",
  },
  ratingNumber: {
    fontFamily: "TT Norms Pro",
    fontSize: "1.7rem",
    fontWeight: "bold",
    color: "#B1EEB7",
    marginRight: "0.5rem",
    backgroundColor: "transparent", 
  },
  ratingBackground: {
    display: "flex",
    backgroundColor: "#FCFCFC",
    opacity: "0.7",
    borderRadius: "10px",
  },
  icon: {
    width: "1rem",
  },
  truth_icon: {
    width: "1.7rem",
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  },
  pic: {
    display: "flex",
    height: "15rem",
    margin: "auto",
  },
  ellipse: {
    width: "1rem",
  },
  a: {
    textDecoration: "none" /* Remove underline */,
    color: "inherit",
  },
});

interface PinnedCardProps {
  news: News;
  setDisplayRatingExplanation: React.Dispatch<React.SetStateAction<boolean>>;
}

// Featured
const PinnedCard: React.FC<PinnedCardProps> = ({news, setDisplayRatingExplanation}) => {
  const classes = useStyles();
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const storedIsLiked = localStorage.getItem(news.id);
    if (storedIsLiked) {
      setLiked(true);
    }
  }, [news.id]);

  const toggleLike = () => {
    const storedIsLiked = localStorage.getItem(news.id);
    if (storedIsLiked) {
      localStorage.removeItem(news.id);
      setLiked(false);
    } else {
      localStorage.setItem(news.id, "true");
      setLiked(true);
    }
  }

  const {
    type,
    summary,
    text,
    author,
    publisher,
    percentile,
    time,
    link,
    img,
    likes,
    comments,
    score,
    impressions,
  } = news;

  const timeAgo = timePassed(new Date(time));

  const renderMetrics = (): React.ReactNode => {
    return (
      <div>
        {likes !== "" && comments !== "" ? (
          <>
            <img className={classes.icon} src="./images/white_impressions.svg" alt="" />
            <span className={classes.metrics}>{impressions}</span>
            <img
              className={classes.icon}
              onClick={toggleLike}
              src={liked ? "./images/red_heart.svg" : "./images/filled_heart.svg"}
              alt=""
            />
            <span className={classes.metrics}>{likes}</span>
            <img
              className={classes.icon}
              src="./images/white_comment.svg"
              alt=""
            />
            <span className={classes.metrics}>{comments}</span>
          </>
        ) : null}
      </div>
    );
  };

  const renderTruthometer = (): React.ReactNode => {
    if (percentile && percentile !== -1)
      return (
        <div className={classes.ratingBackground}>
          <img
            className={classes.truth_icon}
            src="./images/reliability_rating_pinned.svg"
            alt=""
            onMouseEnter={() => setDisplayRatingExplanation(true)}
          />
          <span className={classes.ratingNumber}>{percentile}%</span>
        </div>
      );
  };

  return (
    <a
      href={
        link !== ""
          ? link.indexOf("http") !== -1
            ? link
            : "https://www." + link
          : undefined
      }
      className={classes.a}
      target="_blank"
      rel="noreferrer"
    >
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.title}>
            {summary.split(".")[0].length > 200
              ? summary.split(".")[0].slice(0, 200) + "..."
              : summary.split(".")[0]}
            {renderTruthometer()}
          </div>
          <div className={classes.blurb}>
            <img src="./images/ellipse.png" alt="" />
            <div style={{ marginLeft: "0.25rem" }}>
              {type === "tweet"
                ? author + " via Twitter"
                : author === undefined
                ? publisher
                : author + " via " + publisher}{" "}
              | {timeAgo}
            </div>
          </div>
        </div>

        <img
          className={classes.pic}
          src={img || "./images/defaultNewsImg.png"}
          alt=""
        />

        {renderMetrics()}
      </div>
    </a>
  );
};

export default PinnedCard;
