import { createUseStyles } from "react-jss";
import {
  Container,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { SetStateAction, useState } from "react";

interface TeamProps {
  onTeamSelect: (teamValue: number) => void;
}

const useStyles = createUseStyles({
});

const Team: React.FC<TeamProps> = ({onTeamSelect}) => {
  const classes = useStyles();

  const [value, setValue] = useState("-1");

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setValue(event.target.value);
    onTeamSelect(event.target.value as unknown as number);
  };

  return (
    <FormControl>
      <Select
        value={value}
        defaultValue={value}
        onChange={handleChange}
        fullWidth
        className="formControl"
        sx={{
          width: "100%",
          color: value === "-1" ? "#AAAAAA" : "black",
        }}
      >
        <MenuItem value="-1">Selected Teams</MenuItem>
        <MenuItem value="0">All Teams</MenuItem>
        <MenuItem value="1">Arsenal</MenuItem>
        <MenuItem value="2">Aston Villa</MenuItem>
        <MenuItem value="3">Bournemouth</MenuItem>
        <MenuItem value="4">Brentford</MenuItem>
        <MenuItem value="5">Brighton & Hove Albion</MenuItem>
        <MenuItem value="6">Burnley</MenuItem>
        <MenuItem value="7">Chelsea</MenuItem>
        <MenuItem value="8">Crystal Palace</MenuItem>
        <MenuItem value="9">Everton</MenuItem>
        <MenuItem value="10">Fulham</MenuItem>
        <MenuItem value="11">Liverpool</MenuItem>
        <MenuItem value="12">Luton Town</MenuItem>
        <MenuItem value="13">Liverpool</MenuItem>
        <MenuItem value="14">Manchester City</MenuItem>
        <MenuItem value="15">Manchester United</MenuItem>
        <MenuItem value="16">Newcastle</MenuItem>
        <MenuItem value="17">Nottingham Forest</MenuItem>
        <MenuItem value="18">Tottenham Hotspur</MenuItem>
        <MenuItem value="19">West Ham United</MenuItem>
        <MenuItem value="20">Wolverhampton Wanderers</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Team;
