import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode"
import Home from "./pages/home"
import LandingPage from "./pages/entry"
import Navbar from "./internal/navbar";
import DatabaseRenderer from "./internal/databaseRenderer";
import EditRecord from "./internal/editRecord";
import EditTweet from "./internal/editTweet";
import Create from "./internal/create";
import PreferencesPage from "./pages/preferences";
import { incrementVisits } from "./utils/metricsFunctions";
 
const App = () => {
  const [ user, setUser ] = useState({});
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const navigate = useNavigate();

  const handleCallbackResponse = (response) => {
    console.log("Encoded JWT ID token: " + response.credential);
    const userObject = jwt_decode(response.credential);
    console.log(userObject);
  };

  const initializeGoogleAuth = () => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        "1012909746567-huthpt0d4ntpg90qloj0tht3hf7phrjp.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    console.log("google auth initialized");

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

    console.log("sign in button rendered");
  };

  useEffect(() => {
    // Update site visits
    incrementVisits();
    console.log("incremented visits");

    // Check if "selectedTeams" exists in localStorage
    const selectedTeams = localStorage.getItem('selectedTeams');
    console.log("selectedTeams App.js", selectedTeams)
  
    // If "selectedTeams" exists, redirect to /home
    // if (selectedTeams) {
      // navigate('/home');
    // }
  }, [navigate]);
  

  const MainLayout = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {/* <MainNav /> */}
        {/* <SideBar setIsLoggingIn={setIsLoggingIn}/> */}
        <Routes>
          <Route index element={<LandingPage/>}/>
          <Route path="preferences" element={<PreferencesPage/>}/>
          <Route path="home" element={<Home initializeGoogleAuth={initializeGoogleAuth} isLoggingIn={isLoggingIn} setIsLoggingIn={setIsLoggingIn}/> }/>
          {/* <Route path="sign-in" element={<SignIn initializeGoogleAuth={initializeGoogleAuth}/>} /> */}
        </Routes>
      </div>
    )
  }

  const AdminLayout = () => {
    return (
      <div>
        <Navbar />
        <Routes>
          <Route index element={<DatabaseRenderer />} />
          <Route path="record/edit/:id" element={<EditRecord />} />
          <Route path="tweet/edit/:id" element={<EditTweet />} />
          <Route path="create" element={<Create />} />
        </Routes>
      </div>
    );
  };

 return (
   <div>
     <Routes>
        <Route path="/*" element={<MainLayout />}/>
        <Route path="/admin/*" element={<AdminLayout />} />
     </Routes>
   </div>
 );
};




 
export default App;