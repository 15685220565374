import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Tweet } from "../../../server/schema/representations";
import { BASE_URL } from "../consts";

export default function Create() {
  type Record = {
    name?: string;
    position?: string;
    university?: string;
  };

  const [form, setForm] = useState<Record>({
    name: "",
    position: "",
    university: "",
  });

  const [tweetForm, setTweetForm] = useState<any>({
    _id: "",
    username: "",
    handle: "",
    time: "",
    time_passed: "",
    summary: "",
    text: "",
    link: "",
    img: "",
    img_text: "",
    comments: "",
    retweets: "",
    likes: "",
    views: "",
    predictions: [],
    verification: {
      player: "",
      club: "",
    },
    impressions: 0,
  });

  const navigate = useNavigate();

  // Updates the state properties
  function updateForm(value: Record) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  function updateTweetForm(value: any) {
    return setTweetForm((prev: any) => {
      return { ...prev, ...value };
    });
  }

  // Handle the submission
  async function onSubmitRecord(e: { preventDefault: () => void }) {
    e.preventDefault();

    // When POST is sent to create url, add new record to the database.
    const newPerson = { ...form };

    await fetch(`${BASE_URL}/admin/record`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPerson),
    }).catch((error) => {
      window.alert(error);
      return;
    });

    setForm({ name: "", position: "", university: "" });
    navigate("/admin");
  }

  async function onSubmitTweet(e: { preventDefault: () => void }) {
    e.preventDefault();

    // When POST is sent to create url, add new tweet to the database.
    const newTweet = { ...tweetForm };

    await fetch(`${BASE_URL}/admin/tweet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newTweet),
    }).catch((error) => {
      window.alert(error);
      return;
    });

    setTweetForm({
      _id: "",
      username: "",
      handle: "",
      time: "",
      time_passed: "",
      summary: "",
      text: "",
      link: "",
      img: "",
      img_text: "",
      comments: "",
      retweets: "",
      likes: "",
      views: "",
      predictions: [],
      verification: {
        player: "",
        club: "",
      },
      impressions: 0,
    });
    navigate("/admin");
  }

  // Displays the form that takes user input
  return (
    <div>
      <h3>Add a Person</h3>
      <form onSubmit={onSubmitRecord}>
        <div className="form-group">
          Name
          <input
            type="text"
            className="form-control"
            id="name"
            value={form.name}
            onChange={(e) => updateForm({ name: e.target.value })}
          />
        </div>
        <div className="form-group">
          Position
          <input
            type="text"
            className="form-control"
            id="position"
            value={form.position}
            onChange={(e) => updateForm({ position: e.target.value })}
          />
        </div>
        <div className="form-group">
          University
          <input
            type="text"
            className="form-control"
            id="university"
            onChange={(e) => updateForm({ university: e.target.value })}
          />
        </div>
        <div className="form-group">
          <input
            type="submit"
            value="Create person"
            className="btn btn-primary"
          />
        </div>
      </form>

      <h3>Add a Tweet</h3>
      <form onSubmit={onSubmitTweet}>
        <div className="form-group">
          Username
          <input
            type="text"
            className="form-control"
            id="username"
            value={tweetForm.username}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, username: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Handle
          <input
            type="text"
            className="form-control"
            id="handle"
            value={tweetForm.handle}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, handle: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Time
          <input
            type="text"
            className="form-control"
            id="time"
            value={tweetForm.time}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, time: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="time">Time Passed: </label>
          <input
            type="text"
            className="form-control"
            id="time_passed"
            value={tweetForm.time_passed}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, time_passed: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Text
          <input
            type="text"
            className="form-control"
            id="text"
            value={tweetForm.text}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, text: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Img
          <input
            type="text"
            className="form-control"
            id="img"
            value={tweetForm.img || ""}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, img: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Img Text
          <input
            type="text"
            className="form-control"
            id="imgText"
            value={tweetForm.img_text || ""}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, img_text: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Comments
          <input
            type="text"
            className="form-control"
            id="comments"
            value={tweetForm.comments}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, comments: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Retweets
          <input
            type="text"
            className="form-control"
            id="retweets"
            value={tweetForm.retweets}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, retweets: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Likes
          <input
            type="text"
            className="form-control"
            id="likes"
            value={tweetForm.likes}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, likes: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Views
          <input
            type="text"
            className="form-control"
            id="views"
            value={tweetForm.views}
            onChange={(e) =>
              updateTweetForm({ ...tweetForm, views: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          Predicted player
          <input
            type="text"
            className="form-control"
            id="views"
            value={
              tweetForm.predictions
                ? tweetForm.predictions[0]
                  ? tweetForm.predictions[0].player
                  : ""
                : ""
            }
            onChange={(e) =>
              updateTweetForm({
                ...tweetForm,
                predictions: [
                  {
                    ...(tweetForm.predictions
                      ? tweetForm.predictions[0]
                      : { club: "", certainty: 0 }),
                    player: e.target.value,
                  },
                ],
              })
            }
          />
        </div>
        <div className="form-group">
          Predicted club
          <input
            type="text"
            className="form-control"
            id="views"
            value={
              tweetForm.predictions
                ? tweetForm.predictions[0]
                  ? tweetForm.predictions[0].club
                  : ""
                : ""
            }
            onChange={(e) =>
              updateTweetForm({
                ...tweetForm,
                predictions: [
                  {
                    ...(tweetForm.predictions
                      ? tweetForm.predictions[0]
                      : { player: "", certainty: 0 }),
                    club: e.target.value,
                  },
                ],
              })
            }
          />
        </div>
        <div className="form-group">
          Predicted certainty
          <input
            type="text"
            className="form-control"
            id="views"
            value={
              tweetForm.predictions
                ? tweetForm.predictions[0]
                  ? tweetForm.predictions[0].certainty
                  : 0
                : 0
            }
            onChange={(e) =>
              updateTweetForm({
                ...tweetForm,
                predictions: [
                  {
                    ...(tweetForm.predictions
                      ? tweetForm.predictions[0]
                      : { player: "", club: "" }),
                    certainty: e.target.value as unknown as number,
                  },
                ],
              })
            }
          />
        </div>
        <div className="form-group">
          <input
            type="submit"
            value="Create tweet"
            className="btn btn-primary"
          />
        </div>
      </form>
    </div>
  );
}
