import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  teamCard: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: "gray",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgb(0 0 0 / 0.2)",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  teamCardSelected: {
    boxShadow: "0 0 0 2px #C7342E",
  },
  teamsList: {
    padding: "32px 0",
    display: "grid",
    rowGap: "16px",
    columnGap: "16px",
    gridTemplateColumns: "repeat(3, 1fr)",
    justifyContent: "center",
    margin: "0 1rem 0 1rem",
    zIndex: "1",
    paddingBottom: "5rem",
  },
  teamLogo: {
    width: "110px",
    aspectRatio: "1/1",
    '@media screen and (max-width: 768px)': {
      width: '100px',
    },
    '@media screen and (max-width: 425px)': {
      width: '90px',
    },
    '@media screen and (max-width: 375px)': {
      width: '70px',
    },
    '@media screen and (max-width: 320px)': {
      width: '65px',
    },
  },
  teamName: {
    '@media screen and (max-width: 320px)': {
      fontSize: '12px',
    },
  }
});

interface TeamCollectionProps {
  selectedTeams: string[];
  handleTeamCardSelect: (teamName: string) => void;
  searchTerm: string;
}

export const teamCodes = [
  "all",
  "arsenal",
  "aston-villa",
  "bournemouth",
  "brentford",
  "brighton",
  "burnley",
  "chelsea",
  "crystal-palace",
  "everton",
  "fulham",
  "liverpool",
  "luton_town",
  "man_city",
  "man_united",
  "newcastle",
  "nottingham-forest",
  "sheffield",
  "tottenham",
  "west-ham-united",
  "wolverhampton",
];

export const teamNames = [
  "All",
  "Arsenal",
  "Aston Villa",
  "Bournemouth",
  "Brentford",
  "Brighton & Hove Albion",
  "Burnley",
  "Chelsea",
  "Crystal Palace",
  "Everton",
  "Fulham",
  "Liverpool",
  "Luton Town",
  "Manchester City",
  "Manchester United",
  "Newcastle",
  "Nottingham Forest",
  "Sheffield United",
  "Tottenham Hotspur",
  "West Ham United",
  "Wolverhampton Wanderers",
];

const TeamCollection: React.FC<TeamCollectionProps> = ({
  selectedTeams,
  handleTeamCardSelect,
  searchTerm,
}) => {
  const classes = useStyles();
  const [searchedTeamCodes, setSearchedTeamCodes] =
    useState<string[]>(teamCodes);
  const [correspondingTeamNames, setCorrespondingTeamNames] =
    useState<string[]>(teamNames);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchedTeamCodes(teamCodes);
      setCorrespondingTeamNames(teamNames);
    } else {
      const filteredTeamCodes = teamCodes.filter((teamCode, index) => {
        const teamName = teamNames[index];
        return teamName.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setSearchedTeamCodes(filteredTeamCodes);
      setCorrespondingTeamNames(
        filteredTeamCodes.map((teamCode) => {
          const index = teamCodes.indexOf(teamCode);
          return teamNames[index];
        })
      );
    }
  }, [searchTerm]);

  return (
    <div className={classes.teamsList}>
      {searchedTeamCodes.map((teamCode, index) => {
        return (
          <div
            key={index}
            className={`${classes.teamCard} ${
              selectedTeams.includes(teamCode) ? classes.teamCardSelected : ""
            }`}
            onClick={() => handleTeamCardSelect(teamCode)}
          >
            <img
              src={`./clubs/${teamCode}.svg`}
              alt={teamCode}
              className={classes.teamLogo}
              onError={(e) => {
                // If SVG loading fails, fallback to PNG
                const target = e.target as HTMLImageElement;
                target.onerror = null; // Avoid infinite loop if PNG is also missing
                target.src = `./clubs/${teamCode}.png`; // PNG path
                target.className = classes.teamLogo;
              }}
            />
            <span
              className={classes.teamName}
              style={{
                color: selectedTeams.includes(teamCode) ? "#C7342E" : "",
              }}
            >
              {" "}
              {correspondingTeamNames[index]}{" "}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default TeamCollection;
