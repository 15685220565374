import { BASE_URL } from "../consts";

export const localMetricsAPI = `${BASE_URL}/admin/visits`;

export const getVisits = async () => {
  try {
    const response = await fetch(`${BASE_URL}/visits`);
    if (!response.ok) {
      throw new Error(`An error occurred: ${response.statusText}`);
    }
    const visits = await response.json();
    return visits;
  } catch (error) {
    console.log((error as Error).message);
    console.log("error getting visits");
    return;
  }
}

export const incrementVisits = async () => {
  try {
    await fetch(localMetricsAPI, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("incremented visits");
  } catch (error) {
    console.log(error);
    console.log("error incrementing visits");
    return;
  }
}