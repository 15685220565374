import { News } from "../../../server/schema/representations";

/** Sort in order of time
 * 
 * @param news 
 * @returns 
 */
export const orderActivityFeedNews = (news?: News[]) => {
  return news?.sort((a, b) => ((new Date(a.time)) < (new Date(b.time)) ? -1 : 1)) || [];
}

/** [newsRankSort(news, teamsOfInterest, authorPercentileMap)] returns a sorted 
 * array of News objects ranked in decreasing order of score. An object's score 
 * is made up of three components all scaled to be in the range [0, 1]:
 * 
 * 1) Inverse normalized recency - giving more weight to more recent News while 
 * also accounting for different News array lengths
 * 2) Normalized credibility - simply using our existing credibility rating and 
 * scaling it to be between [0, 1]
 * 3) Weighted relevance - adjusted preference given to News objects that pertain 
 * to one of the user's teams of interest
 * 
 * @param
 * [news] is the array containing the News objects to be processed
 * @param
 * [teamsOfInterest] has the names of all teams the user is interested in
 * @param
 * [authorPercentileMap] is our pre-existing map from authors to their percentile
 */
export const newsRankSort = (news: News[], authorPercentileMap: Map<String, number>, teamsOfInterestStr: string | null) => {
  const RECENCY_WEIGHT = 0.15
  const CREDIBILITY_WEIGHT = 0.375
  const RELEVANCE_WEIGHT = 0.475

  const teamsOfInterest: string[] = teamsOfInterestStr ? JSON.parse(teamsOfInterestStr) : [];

  /** Assigns a score to a singular News object */
  const assignScore = (newsObject: News, currentIndex: number) => {
    // [TODO]: temporary test of relevance
    const isRelevant = teamsOfInterest.some(team => newsObject.text.includes(team))

    // Inverse normalized recency
    const recencyFactor = Math.max(1 - currentIndex / 100, 0)
    // Normalized credibility (default to 25)
    const credibilityFactor = (authorPercentileMap.get(newsObject.author) || 25) / 100
    // Weighted relevance
    const relevanceFactor = isRelevant && (teamsOfInterest.length > 0) ? (1 / (1.2 ** teamsOfInterest.length)) : 0 

    return RECENCY_WEIGHT * recencyFactor + CREDIBILITY_WEIGHT * credibilityFactor + RELEVANCE_WEIGHT * relevanceFactor
  }

  // Create a new array with the additional `score` field
  const newsWithScore = news.map((newsObject, index) => ({
    ...newsObject,
    score: assignScore(newsObject, index),
  }))

  newsWithScore.sort((a, b) => b.score - a.score);

  return newsWithScore;
}

export const timePassed = (date: Date) => {
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - date.getTime()

  // time intervals in ms
  const minute = 60 * 1000
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;

  if (timeDifference < minute) {
    return "Just now"
  } else if (timeDifference < hour) {
    const minutesAgo = Math.floor(timeDifference / minute);
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  } else if (timeDifference < day) {
    const hoursAgo = Math.floor(timeDifference / hour);
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else if (timeDifference < week) {
    const daysAgo = Math.floor(timeDifference / day);
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  } else {
    return 'More than a week ago';
  }

}