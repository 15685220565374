import { createUseStyles } from "react-jss";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = createUseStyles({
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10rem",
  },
  loadingMessage: {
    marginBottom: "1rem",
  }
})

export default function LoadingUI() {
  const classes = useStyles();

  return (
      <div className={classes.loadingContainer}>
        <span className={classes.loadingMessage}>
          Hang tight! We're working on generating the most relevant football transfer news for you.
        </span>
        <CircularProgress color="error" size="5rem"/>
      </div>
  );
}