import { createUseStyles } from "react-jss";
import { Box, Button, Container, IconButton, InputAdornment, Rating, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SetStateAction, useRef, useState } from "react";

const useStyles = createUseStyles({
  ratingExplanationPopupBackground: {
    backgroundColor: "rgba(112, 112, 112, 0.8)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  ratingExplanationPopupContainer: {
    borderRadius: 8,
    padding: "2rem",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 380,
    height: 425,
    background: "white",
    alignItems: "flex-start",
    // backgroundImage: "url('./images/ratingPopupBackground.png')", // Set the background image here
    // backgroundSize: "100% 100%", 
  },

  header: {
    color: "#DD1C1C",
    fontSize: "1.5rem",
    fontWeight: "600",
    width: "18rem",
    marginBottom: "2rem",
    position: "relative",
    zIndex: 2,
  },

  body: {
    color: "#838383",
    position: "relative",
    zIndex: 2,
  },

  backgroundImage: {
  
   
  }

});

interface RatingExplanationModalProps {
  setDisplayRatingExplanation: React.Dispatch<React.SetStateAction<boolean>>
}

const RatingExplanationModal: React.FC<RatingExplanationModalProps> = ({ setDisplayRatingExplanation }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  
  return (
    <div className={classes.ratingExplanationPopupBackground} onClick={() => setDisplayRatingExplanation(false)}>
      <div className={classes.ratingExplanationPopupContainer} ref={containerRef} onClick={(e) => e.stopPropagation()}>

        <IconButton
          style={{ position: "absolute", top: "0.2rem", right: "0.2rem", zIndex: 2}}
          onClick={() => setDisplayRatingExplanation(false)}
        >
          <CloseIcon />
        </IconButton>

        <div className={classes.header}> Touchline's Reliability Rating </div>

        <div className={classes.body}> 
          Using our ranking algorithm, Touchline recognizes
          accurate and exclusive journalism by dyanmically awarding credibility points 
          and rating news based on what matters most to you.
        </div>

        {/* <Typography variant="body2"> Brought to you by Touchline Inc. </Typography> */}

      </div>
  </div>
  );
};

export default RatingExplanationModal;
