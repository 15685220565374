import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  main: {
    display: "flex",
    alignItems: "center",
    margin: "0.75rem 0.75rem",
    // marginBottom: "2.5rem",
  },
  logo: {
    width: "2rem",
    marginRight: "0.5rem",
  },
  header: {
    fontSize: "40px",
    fontWeight: "bold",
    color: "#db3939",
    width: "100%",
    margin: 0,
    font: "TT Norms Pro",
  },
});

export default function MainNav() {
  const classes = useStyles();

  return (
    <a href="/" style={{ textDecoration: "none" }}>
      <div className={classes.main}>
        <img
          className={classes.logo}
          src="./images/touchline.png"
          alt="Touchline Logo"
        />
        <div className={classes.header}> TOUCHLINE </div>
      </div>
    </a>
  );
}
